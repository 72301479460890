<template>
<div id="topnav"></div>
    <section class="register-area">
      <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
          <div class="register-image">
            <img src="assets/img/register-bg.jpg" alt="image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
          <div class="register-content">
            <div class="d-table">
              <div class="d-table-cell">
                <div class="register-form">
                  <div class="logo">
                    <router-link :to="{ name: 'Home' }"><img  class="logo" src="@/assets/img/logo.png" alt="image" /></router-link>
                  </div>
                  <h3>Open up your Trainee Account now</h3>
                  <p>Already signed up? <router-link :to="{ name: 'Login' }">Log in</router-link></p>
                  <p>Become an Instructor? <router-link :to="{ name: 'TrainerRegister' }">Trainer Register</router-link></p>
                  <form @submit.prevent="registerUser">
                      <div class="form-group">
                      <input type="text" v-model="form.fname" id="fname" placeholder="Your First Name" class="form-control" />
                      <div v-if="errors.fname" class="text-small text-danger text-start">{{ errors.fname[0] }}</div>
                    </div>
                    <div class="form-group">
                      <input type="text" v-model="form.sname" id="sname" placeholder="Your Second Name" class="form-control" />
                      <div v-if="errors.sname" class="text-small text-danger text-start">{{ errors.sname[0] }}</div>
                    </div>
                    <div class="form-group">
                      <input type="text" v-model="form.lname" id="email" placeholder="Your Surname" class="form-control" />
                      <div v-if="errors.lname" class="text-small text-danger text-start">{{ errors.lname[0] }}</div>
                    </div>
                    <div class="form-group">
                      <input type="email" v-model="form.email" id="email" placeholder="Your email address" class="form-control" />
                      <div v-if="errors.email" class="text-small text-danger text-start">{{ errors.email[0] }}</div>
                    </div>
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <input :type="passwordFieldType" v-model="form.password" id="password" placeholder="Create a password" class="form-control" />
                            <a @click="switchVisibility" class="input-group-text text-end">
                                <div v-if="passwordIcon == true">
                                    <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                </div>
                                <div v-else>
                                    <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                                </div>
                            </a>
                        </div>
                        <div v-if="errors.password" class="text-small text-danger text-start">{{ errors.password[0] }}</div>
                    </div>
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <input :type="confirmFieldType" v-model="form.password_confirmation" id="password" placeholder="Confirm a password" class="form-control" />
                            <a @click="switchConfirmVisibility" class="input-group-text">
                                <div v-if="confirmIcon == true">
                                    <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                </div>
                                <div v-else>
                                    <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                                </div>
                            </a> 
                        </div> 
                        <div v-if="errors.password_confirmation" class="text-small text-danger text-start">{{ errors.password_confirmation[0] }}</div>
                    </div>
                    <button type="submit">Sign Up</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
        values: [],
        form: {
          email: '',first_name: '', second_name: '', last_name: '',password : '',terms: '', roles: 3, password_confirmation: '', device_name: 'browser',
        },
        error:null,
        passwordFieldType: "password",
        confirmFieldType: "password",
        passwordIcon: true,
        confirmIcon: true,
        errors:[]
    }
  },
  methods:{
    registerUser(){
        axios.post('https://apitraining.vipawaworks.com/api/auth/user/register', this.form).then(response => {
            localStorage.setItem('user_data', response.data.token)
            this.$router.push(this.$route.query.redirect || { name: 'TrainerDashboard'})
        }).catch((error) => {
            this.errors = error.response.data.errors
        })
    },
    switchVisibility() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
        this.passwordIcon = this.passwordIcon === true ? false : true
    },
    switchConfirmVisibility() {
        this.confirmFieldType = this.confirmFieldType === "password" ? "text" : "password"
        this.confirmIcon = this.confirmIcon === true ? false : true
    }
  }    

}
</script>

<style>

</style>